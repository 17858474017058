.company-notes-container {
    padding-top: 20px;
}

.company-notes {
    border: 1px solid #ccc;
    padding: 10px;
}

.company-notes-header {
    color: #cc0000;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 12pt;
    text-align: center;
}

.company-notes-message {
    color: #cc0000;
    font-family: Helvetica, Verdana, Arial;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10pt;
    text-align: center;
    padding-bottom: 10px;
}

.company-notes-text {
    padding: 10px;
    color: #000;
    font-weight: bold;
    font-family: Helvetica, Verdana, Arial;
    font-size: 12pt;
}

.company-notes-table {
    width: 100%;
    padding: 20px 10px 10px 10px ;
}

.company-notes-table-header {
    font-weight: bold;
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    padding-right: 5px;
}

.company-notes-table-text {
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    vertical-align: top;
    text-align: left;
}