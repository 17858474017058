@import 'App.scss';

.stops {
    &-company-name {
        font-weight: bold;
        line-height: 1.25em;
        margin-top: .3em;
    }

    &-address {
        display: flex;
        align-items: center;
    }

    &-mileage {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0.5em;
        color: black;
        text-align: left;
        color: #666666;
    }

    &-due-out {
        line-height: 1.25em;
        margin-bottom: .3em;
    }

    &-our-color {
        text-align: center;
        color: black;
    }

    &-our-stops {
        vertical-align: auto;
        text-align: left;
    }

    &-map-marker {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 15px;
        border-radius: 0.5em;
        color: $app-color;
        margin: .3em 0em 0em 0em;
    }

    &-event-text {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 15px;
        background-color: white;
        color: $app-color;
        margin: .6em 0em 0em 0em;
        font-size: 0.8em;
        font-weight: bold;
        border: .5px solid $app-color;
        border-radius: 0.5em;
    }
}
