@import 'App.scss';

.order-heading-item {
    &-container {
        display: flex;
        flex: 1;
        align-content: center;
        align-items: center;
        height: 60px;
        width: 100%;
        text-align: left;
        background-color: #F2F2F2;
        border-bottom: $divider-line-light;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-color: lightgray;
    }

    &-title {
        display: flex;
        align-content: center;
        align-items: center;
        font-size: $font-size-heading;
        font-weight: bold;
        color: #0066A4;
        margin-left: 10px;
    }

    &-type {
        width:100%;
        display: flex;
        flex: 1;
        justify-content: right;
        align-content: center;
        align-items: center;
        text-align: right;
        margin-right: 10px;
    }

    &-type-desc-text {
        font-size: $font-size-heading;
        font-weight: bold;
        margin-left: 6px;
    }
}

