@import 'App.scss';

.due-out {
    &-truck-icon {
        height: 15px;
        color: $app-color;
        margin-top: .5em;
    }

    &-truck-icon-div {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
    }

    &-color {
        color: black;
    }

    &-div {
        display: flex;
        justify-content: left;
        vertical-align: auto;
        margin-top: .3em;
        margin-bottom: .3em;
    }
}
