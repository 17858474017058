@import 'App.scss';

.header-box {
    &-heading-item {
        &-container {
            height: auto;
            width: 100%;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: #EBEBEB;
            border-bottom: $divider-line-light;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-color: lightgray;
        }

        &-title {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #EB5757;
        }

        &-title-unconfirmed {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #EB5757;
        }

        &-title-confirmed {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #8BC34A;
        }

        &-title-confirmed-waiting {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #EB5757;
        }

        &-title-confirmed-ready {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #8BC34A;;
        }

        &-title-appointmentdetails {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #0066A4;
        }
    }

    &-blue-heading-item {
        &-container {
            height: 60px;
            width: 100%;
            text-align: center;
            padding-top: 15px;
            background-color: #F2F2F2;
            border-bottom: $divider-line-light;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-color: lightgray;
        }

        &-title-unconfirmed {
            font-size: $font-size-heading;
            font-weight: bold;
            color: #0066A4;
        }
    }
}
