#imageStyle {
    width: 401px;
    height: 425px;
}

@media screen and (max-width: 992px) {
    #imageStyle {
        width: 425px;
        height: 455px;
    }
  }

  @media screen and (max-width: 600px) {
    #imageStyle {
        width: 295px;
        height: 365px;
    }
  }