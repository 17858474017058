@import 'App.scss';

.confirm-button {
    &-button {
        width: 45%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #8bc34a;
        color: white;
    }
}
