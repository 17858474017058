.load-indicator-container {
  width: 150px;
  margin: auto;
  text-align: center;
  border: 0;
  margin-top: 10px;
  height: 100%;
  vertical-align: middle;
}

.submit-trimble-btn-wrapper {
  padding-top: 20px;
}

.submit-trimble-btn {
  background-color: white;
  color: black;
  border: 2px solid #004b6d;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}

.submit-trimble-btn:hover {
  background-color: #004b6d;
  color: white;
}
