@import 'App.scss';

.confirm-box {
    &-left {
        color: black;
        text-align: left;
        margin: 0.5em;
        font-size: 13px;
        font-weight: bolder;
    }

    &-center {
        color: black;
        text-align: center;
        margin: 0.5em;
        font-size: 13px;
        font-weight: bolder;
    }

    &-right {
        color: black;
        text-align: right;
        margin: 0.5em;
        font-size: 13px;
    }

    &-borders {
        height: 100%;
        margin-left: 6px;
        margin-right: 6px;
        border-bottom: $divider-line-light;
    }
}
