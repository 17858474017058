:root {
    --app-height: 98vh;
    --app-width: 100vw;
    --title-bar-height: 36px;
    --main-tab-height: 50px;
    --keyword-search-height: 49px;
    --map-view-tab-height: 51px;
 }
 
 html, body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    height: var(--app-height);
    width: var(--app-width);
}

pre {
    display: block;
    font-family: monospace;
    white-space: pre-wrap;
    margin: 1em 0;
}

.container {
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
}

.header {
    padding: 10px; 
    font-weight: bold; 
    font-size: x-large;
    border: 1px solid #ccc;
}

.header img {
    vertical-align: middle; 
    padding-right: 10px;
}

.card {
    margin: auto;
    background-color: #fff;
}

.container-content {
    padding-top: 5px;
    padding-bottom: 20px;
    padding-left: 5px; 
    padding-right: 5px;
}

.load-indicator-container {
    width: 150px;
    margin: auto;
    text-align: center;
    border: 0;
    margin-top: 10px;
    height: 100%;
    vertical-align: middle;
}

.no-load-found {
    text-align: center;
    margin-top : 30px;
    border: 1px solid #fff;
    font-size: large;
    padding: 20px;
    color: rgb(184, 0, 0);
    font-weight: bold;
}

.dx-tabs {
    border-right: 1px solid #ccc !important;
    border-left: 1px solid #ccc !important;
    border-top: 0 !important;
}

.dx-item .dx-toolbar-item .dx-toolbar-label .header-title {
    padding-top: 10px;
    padding-bottom: 10px;
}

.dx-toolbar .dx-toolbar-items-container {
    height: var(--title-bar-height);
    padding-bottom: 20px !important;
}

.dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
    height: var(--title-bar-height);
    padding-bottom: 20px !important;
}

.dx-drawer-content {
    height: calc(var(--app-height) - var(--title-bar-height)) !important;
    max-height: calc(var(--app-height) - var(--title-bar-height)) !important;
}

.dx-scrollview-content {
    height: calc(var(--app-height) - var(--title-bar-height)) !important;
    max-height: calc(var(--app-height) - var(--title-bar-height)) !important;
}

.content {
    height: calc(var(--app-height) - var(--title-bar-height)) !important;
    max-height: calc(var(--app-height) - var(--title-bar-height)) !important;
    min-height: calc(var(--app-height) - var(--title-bar-height)) !important;
    /* background-color: #fff; */
}

.container > .layout-body > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
    height: calc(var(--app-height) - var(--title-bar-height)) !important;
    max-height: calc(var(--app-height) - var(--title-bar-height)) !important;
    min-height: calc(var(--app-height) - var(--title-bar-height)) !important;
}

#directionsScrollview {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

#preferredFuelLocationsScrollview {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

#directionsScrollview .dx-scrollable-content {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

#preferredFuelLocationsScrollview .dx-scrollable-content {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

#directionsScrollview .dx-scrollview-content {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

#preferredFuelLocationsScrollview .dx-scrollview-content {
    max-height: calc(var(--app-height) - var(--title-bar-height) - var(--keyword-search-height) - var(--main-tab-height)) !important;
}

.dx-tab-text {
    font-size: 10pt;
}
