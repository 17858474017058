.action-delete-btn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}

.action-delete-btn:hover {
  background-color: #f44336;
  color: white;
}
