#gridContainer {
  margin: 0px auto !important;
}

.dx-datagrid-table > tbody > tr > td {
  text-align: center !important;
}

.options {
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}

#create-submission-btn {
  border-radius: 4px !important;
  background-color: #004b6d !important;
  color: #fff !important;
  display: block !important;
  margin: auto !important;
}

#resubmit-btn {
  border-radius: 4px !important;
  background-color: #004b6d !important;
  color: #fff !important;
}
