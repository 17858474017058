.dx-fileuploader-content{
  position: relative!important;
  bottom: 1rem!important;
}

.inputfile {
  /* visibility: hidden etc. wont work */width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;      
}

#img-upload-label{
  text-decoration: underline;
  color:blue;
  cursor:pointer;
  font-size: x-large;
}

#input-file-div{
  position: relative;
  top:6px;
  left:0.5rem;
}

.card-div {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);    
  background: #ffffff;
  padding: 30px 20px 20px 20px;
}

.clear-image-div{
  position: relative;
  left:4rem;
}

#create-new-expense-btn{
  margin: 2rem auto!important;
}

.upload-warning {
  font-family: inherit;
  font-weight: 600;
  margin-top: 10px;
}