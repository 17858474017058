#map {
    width: 100%; 
    height: calc(var(--app-height) - var(--title-bar-height) - var(--main-tab-height) - var(--keyword-search-height) - var(--map-view-tab-height));
    min-height: calc(var(--app-height) - var(--title-bar-height) - var(--main-tab-height) - var(--keyword-search-height) - var(--map-view-tab-height));
    margin: auto !important; 
    border-bottom: 1px solid #ccc;
}

.poi-popup-header {
    font-weight: bold;
}

.poi-popup-address {
    font-style: italic;
}

.trimblemaps-ctrl-attrib {
    visibility:  hidden !important;
}