html, body {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
}
.container {
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
}
.header {
    padding: 5px; 
    font-weight: bold; 
    font-size: 15px;
    border: 1px solid #ccc;
    background-color: #363640;
    color: #fff;
    white-space: nowrap;
}
.header img {
    vertical-align: middle; 
    padding-right: 10px;
}

.header-table {
    width: 100%;
}

.header-button {
    width: 1%;
    white-space: nowrap;
    padding-right: 10px;
}

.header-text {
    width: 99%;
    text-align: left;
}

.card {
    box-shadow: 5px 5px 5px grey;
    background-color: #fff;
    max-width: 90%;
    margin: auto;
    border: 1px solid #ccc;
}
.transparent-card {
    max-width: 90%;
    margin: auto;
    padding: 0;
}

.transparent-card-container-left {
    padding-right: 5px;
    height: 100%;
}

.transparent-card-container-right {
    padding-left: 5px;
    height: 100%;
}

.transparent-card-container-center {
    padding-right: 5px;
    padding-left: 5px;
    height: 100%;
}

.transparent-card .card {
    max-width: 100%;
    height: 100%;
}
.transparent-card .card:first-child {
    margin-left: 0;
}
.transparent-card .card:last-child {
    margin-right: 0;
}

.container-content {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px; 
    padding-right: 20px;
    height: 94.5%;
}

.load-indicator-container {
    width: 100%;
    margin: auto;
    text-align: center;
    max-width: 200px;
    margin-top: 50px;
    border: 0;
}
.no-load-found {
    text-align: center;
    margin-top : 30px;
    border: 1px solid #fff;
    font-size: large;
    padding: 20px;
    color: rgb(184, 0, 0);
    font-weight: bold;
}

.summary-table {
    width: 30%;
}

.summary-table th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
}

.summary-table td {
    width: 99%;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
}

.summary-table td.name {
    font-weight: bold;
}

.summary-table td.right-align {
    text-align: right;
}

.dxg-linear-gauge {
    height: 100%;
}

.dxg-range-container {
    height: 100%;
}

#mileage-saved-chart {
	height: 100%;
}

#gallons-saved-chart {
	height: 100%;
}

#oor-chart {
	height: 100%;
}

.title-card {
    max-width: 90%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.title-card h4, h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
}

.green {
    color: rgb(0, 175, 0);
}

.red {
    color: #f00;
}

.navigation-header-right {
    padding-left: 10px;
}

.mileage-indicator {
    color: #00f;
    font-size: 13px;
    font-weight: bold;
}
.mileage-message {
    color: #00f;
    font-size: 10pt;
}

.mileage-message-card {
    max-width: 90%;
    margin: auto;
    padding: 10px;
}

.filter-container {
    width: 100%;
    max-width: 90%;
    margin: auto;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 5px 5px 5px grey;
}

.filter-table {
    width: 100%;
}

.filter-summaries-period {
    padding-right: 20px;
}

.filter-summaries-negative {
    width: 160px;
    white-space: nowrap;
}

.filter-by-driver-driver {
    width: 49%;
    padding-right: 20px;
}
.filter-by-driver-three-columns {
    width: 30%;
    padding-right: 20px;
}
.filter-by-driver-period {
    width: 49%;
    padding-right: 20px;
}

.filter-by-driver-negative {
    width: 2%;
    min-width: 160px;
    white-space: nowrap;
}

.filter-by-load-number-load-number {
    width: 99%;
}

.filter-by-load-number-button {
    width: 1%;
    white-space: nowrap;
}

.product-table {
    width: 100%;
}

.product-table td {
    text-align: right;
    font-size: 13px;
}

.product-table td:first-child {
    text-align: left;
    width: 20%;
    white-space: nowrap;
}

.product-table td:last-child {
    text-align: left;
    width: 2%;
    white-space: nowrap;
}

#accordion {
    margin: 0;
}

#accordion h1 {
    font-size: 15px;
}

#accordion h1,
#accordion p {
    margin: 0;
}

