.action-edit-btn{
    background-color: white; 
    color: black; 
    border: 2px solid #4CAF50;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    width:100%;
    border-radius: 10px;
}

.action-edit-btn:hover{
    background-color: #4CAF50;
    color: white;
}

.preview-receipt-btn{
    background-color: white; 
    color: black; 
    border: 2px solid #004b6d;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 50px;width: 6rem;
    font-size: 0.8rem;
}

.preview-receipt-btn:hover{
    background-color: #004b6d;
    color: white;
}

.driver-info{
    padding: 16px;
    font-size: 14px;
    background: #004b6d;
    display: block;
    color: white;
    margin:0px!important;
}

.dx-master-detail-cell{
    background: aliceblue!important;
}

.dx-header-row td{
    color:black!important;
}
 
.dx-field-item-label-text{
    color: black!important;
    font-weight: 900!important;
}

.dx-datagrid-headers{
    background: whitesmoke!important;
}

button:disabled{
    background: #cccccc;
    color:#666666;
    cursor: not-allowed;
    border:none;
}

button:disabled:hover{
    background: #cccccc;
    color:#666666;
    cursor: not-allowed;
    border:none;
}

.dx-datagrid-nowrap, .dx-datagrid-nowrap .dx-header-row>td>.dx-datagrid-text-content {
    white-space: break-spaces!important;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-adaptive-item-text {
    padding-top: 0px!important; 
    padding-bottom: 0px!important;
    padding-left: 0px!important;
}

.dx-datagrid-rowsview .dx-adaptive-detail-row .dx-field-item-content {
    line-height: 1em!important;
    font-size: 14px!important;
}

