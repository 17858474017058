@import 'App.scss';

.load-confirmation-bold {
    font-size: 10em;
}

.dx-box-borders {
    height: 200px;
    width: 200px;
    border: 5px solid lightgray;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.main-container-item {
    &-container {
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border: $divider-line-dark;
        width: 98%;
        text-align: center;
        background-color: white;
        max-width: 500px;
    }

    &-title {
        font-size: $font-size-heading;
        font-weight: bold;
    }
}
.inside-card {
    margin-left: 10px;
    margin-right: 10px;
}
.bottomPadded {
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
}
.autoWidth {
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.preferred-route-button {
    &-div {
        height: 60px;
        border-top: $divider-line-light;
        border-top-color: lightgray;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 15px;
    }

    &-button {
        width: 45%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #0066A4;
        color: white;
    }
}

.paddedSpan {
    padding-left: 15px;
}
