@import 'App.scss';

.load-header {
    &-weight {
        font-weight: bold;
        margin-top: 0.75em;
        line-height: 0.6em;
        justify-content: left;
        display:flex;
    }

    &-right-border {
        margin-top: 0.525em;
        margin-left: 0%;
        border-right: $divider-line-light;
        margin-right: 8px;
        height: 80%;
    }

    &-clipboard {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-top: 5px;

        &-red {
            color: #EB5757;
        }

        &-green {
            color: #8BC34A;
        }
    }

    &-our-load-color {
        color: black;
    }
}
