.keyword-search-container {
    width: 100%;
    max-width: 100%;
    margin: auto;
    padding: 3px;
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.keyword-search-container .dx-placeholder {
    font-size: 11pt;
}

.keyword-search-table {
    width: 100%;
}

.keyword-search-textbox-container {
    width: 99%;
}

.keyword-search-button-container {
    width: 1%;
    white-space: nowrap;
}