.action {
    &-container {
        display: flex;
        justify-content: center;
    }

    &-button {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    &-icon {
        font-size: 20px !important;
    }

    &-text {
        color: white;
        padding-left: 5px;
    }
}

.disabled {
    opacity: 0.24;
}