@import 'App.scss';

.title-box {
    color: $app-color;
    font-weight: bold;
    font-size: large;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    border-radius: 0.5em;
}