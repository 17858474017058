.direction-first {
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    font-weight: bold;
}

.direction {
    padding-top: 15px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    font-weight: bold;
}
.sub-direction-description {
    width: 98%;
    padding-right: 5px;     
    padding-left: 20px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
}

.sub-direction-distance {
    width: 1%;
    padding-right: 5px;     
    padding-left: 5px;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-align: right;
    vertical-align: top;
}

.sub-direction-time {
    width: 1%;
    padding-right: 5px;
    padding-left: 5px;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-align: right;
    vertical-align: top;
}

.leg-table {
    width: 100%;
}

.leg-table-header {
    text-align: left;
    font-family: Helvetica, Verdana, Arial;
    font-size: 12pt;
}

.leg-table-header-table {
    width: 100%;
}

.leg-table-header-from {
    width: 50px;
    font-weight: normal;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
}

.leg-table-header-to {
    width: 50px;
    font-weight: normal;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    vertical-align: top;
}

.leg-table-header-stop {
    font-size: 12pt;
    font-family: Helvetica, Verdana, Arial;
    padding-bottom: 15px;
}

.leg-table-column-header {
    padding-top: 12px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-decoration: underline;
}

.leg-separator {
    margin-top: 30px;
    margin-bottom: 20px;
}

.leg-table-header-to-address {
    font-weight: normal;
    font-size: 11pt;
}

.leg-table-header-to-name {
    font-size: 11pt;
}

.directions-no-data {
    padding-top: 12px;
    text-align: center;
}

.company-notes-container {
    padding-top: 20px;
}

.company-notes {
    border: 1px solid #ccc;
    padding: 10px;
}

.company-notes-header {
    color: #cc0000;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 12pt;
    text-align: center;
}

.company-notes-message {
    color: #cc0000;
    font-family: Helvetica, Verdana, Arial;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10pt;
    text-align: center;
    padding-bottom: 10px;
}

.company-notes-text {
    padding: 10px;
    color: #000;
    font-weight: bold;
    font-family: Helvetica, Verdana, Arial;
    font-size: 12pt;
}

.company-notes-table {
    width: 100%;
}

.company-notes-table-header {
    font-weight: bold;
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    padding-right: 5px;
}

.company-notes-table-text {
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    vertical-align: top;
}



.directions-not-available {
    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    &-image {
        color: #5e5e5e;
        font-size: 10em;
    }

    &-text {
        font-size: 1.5em;
    }
}