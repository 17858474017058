#submit-btn{
    border-radius: 4px!important;
    background-color: #004b6d!important;
    color: #fff!important; 
    margin-left: 1em!important;   
}

 .dx-state-disabled  .dx-button-content{
    background: white!important;
}

.dx-datagrid-content{
    max-height:60vh!important;
    overflow: auto!important;
}