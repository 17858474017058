$max-height-details: 1000px;
$divider-line-light: 1px solid lightgray;
$divider-line-dark: 2px solid darkgray;
$font-size-heading: 18px;
$font-size-maintext: 16px;
$font-size-innertext: 14px;
$font-color: #5C5C5C;
$app-color: #0066A4;
$row-margin: 10px;
$title-bar-height: 50px; /* height of header */
$dx-drawer-height: calc(100vh - 50px); /* subtract height of header */
$multiview-card-height: calc(100vh - 125px); /* verified , not verified card expands based on the screen height */

.dx-scrollable-native.dx-scrollable-vertical, .dx-scrollable-native.dx-scrollable-vertical > .dx-scrollable-wrapper > .dx-scrollable-container {
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
}

.tabs {
    position: absolute;
    bottom: 0;
    border-top: 0.1em solid #A3A3A3;
}

.dx-loadindicator-icon {
    width: 70% !important;
    height: 70% !important;
}

.app-color{
    color: $app-color;
}

.card-material {
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,0.4);
    border-radius: 10px;
    background-color: #fff;
    margin: 1.5em;
    border: $divider-line-dark;
    overflow: hidden;
}

.center {
    text-align: center;
    align-items: center;
}

.vertical-center {      
    right:5em;
    margin:auto;
}

.left {
    text-align: left;
    float:left;
}

.right {
    text-align: right;
    float: right;
}

.load-indicator {
    text-align: center;
    font-size: 1.5em;
}

.bold {
    font-weight: bold;
}

.dx-drawer-content {
    height: $dx-drawer-height !important; /* subtract height of header */
}

.card-details {
    max-height: 0;
    overflow: hidden;

    &-enter {
        max-height: 0;

        &-active {
            max-height: $max-height-details;
            transition: max-height 1000ms;
        }

        &-done {
            max-height: $max-height-details;
        }
    }

    &-exit {
        max-height: $max-height-details;

        &-active {
            max-height: 0;
            transition: max-height 200ms;
        }

        &-done {
            max-height: 0;
        }
    }

}

.dividerline-margins {  
    border-bottom: $divider-line-light;
}

.bottom-margins{
    margin-bottom: 0.8em;
}

.left-margins {
    margin-left: 0.5em;
}

.right-margins {
    margin-right: 0.5em;
}

.left-margins-double {
    margin-left: 1em;
}

.right-margins-double {
    margin-right: 1em;
}

.center-icon {
    justify-content: center;
    align-items: center;
    display: flex;
}

.columns2 {
    width:100%;
    display: flex;
}

.columns2 > div {
    flex: 5;
    justify-content: left;
    align-items: normal;
}

.columns2 > div:nth-of-type(2) {
    flex: 1;
}

.msg-borders {
    height: 100%;
    border-bottom: $divider-line-light;
}

.block {
    display: inline-block;
}

.rect {
    font-size: 2.5em;
    height: 100%;
}

.red-component {
    color: red;
}

.full-height {
    height: 100%;
}
