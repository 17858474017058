@import 'App.scss';

.load-totals {

    &-columns {
        display: flex;
    }

    &-columns > div {
        flex: 1;
        margin: 5px 5px 5px 0.5em;
        justify-content: left;
        align-items: normal;
    }

    &-weight {
        font-weight: bolder;
    }

    &-columns > div:nth-of-type(1) {
        border-right: $divider-line-light;
    }

    &-columns > div:nth-of-type(2) {
        flex: 1;
    }

    &-flexit {
        display: inline-block;
    }

    &-our-text {
        color: black;
        text-align: left;
        margin: 0px;
        font-size: 13px;
        font-weight: bolder;
    }

    &-our-totals {
        color: black;
        text-align: right;
        margin-left: 0.5em;
        margin-right: 0.8em;
        font-size: 13px;
    }
}
