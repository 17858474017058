@import 'App.scss';

.msg-box {
    &-sticky-note {
        height: 15px;
        color: $app-color;
        margin-top: .5em;
    }

    &-sticky-note-div {
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        margin-bottom: 0.3em;
    }

    &-our-color {
        text-align: center;
        color: black;
    }

    &-our-msg {
        display: flex;
        justify-content: left;
        vertical-align: auto;
        color: red;
        text-align: left;
        font-style: italic;
        margin-top: .3em;
        margin-bottom: .3em;
    }
}
