.fuelStopMarker {
    background-image: url('https://maps.alk.com/api/1.2/img/fuel.png');
    background-size: cover;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}

.fuel-stops-table {
    width: 100%;
}

.fuel-stop-description-first {
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    font-weight: bold;
}

.fuel-stop-description {
    padding-top: 15px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 11pt;
    font-weight: bold;
}

.fuel-stop-address {
    width: 98%;
    padding-right: 5px;     
    padding-left: 20px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
}

.fuel-stop-distance-from-origin {
    width: 1%;
    padding-right: 2em;     
    padding-left: 1em;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-align: right;
}

.fuel-stop-price {
    width: 1%;
    padding-right: 1.5em;     
    padding-left: 1em;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-align: right;
}

.fuel-stop-distance-off-route {
    width: 1%;
    padding-right: 2em;     
    padding-left: 1em;
    white-space: nowrap;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-align: right;
}

.fuel-stop-column-header {
    padding-top: 15px;
    font-family: Helvetica, Verdana, Arial;
    font-size: 10pt;
    text-decoration: underline;
    white-space: nowrap;
}

.fuel-stop-no-data {
    padding-top: 15px;
    text-align: center;
}